import { IComponentController } from '@wix/editor-elements-types';
import { BackToTopButtonImperativeActions } from '../BackToTopButton.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): BackToTopButtonImperativeActions => ({
  onShow: () => {
    updateProps({
      isVisible: true,
    });
  },
  onHide: () => {
    updateProps({
      isVisible: false,
    });
  },
});

export default mapActionsToProps;

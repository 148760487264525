import * as React from 'react';
import { LinkProps } from '@wix/thunderbolt-components';
import { MenuItemProps as ExpandableMenuItemProps } from '@wix/thunderbolt-components-native';
import {
  ExpandableMenuItemWithIsSelected,
  ExpandableMenuProps,
} from '../ExpandableMenu.types';
import * as translations from '../translations/constants';
import { isEmptyObject } from '../../../core/commons/utils';
import style from './style/ExpandableMenu.scss';
import ExpandableMenuItem from './ExpandableMenuItem';

const isAnchorLink = (link: LinkProps) =>
  !!link.anchorDataId || !!link.anchorCompId;

const getIsActiveItem = (item: ExpandableMenuItemProps, href: string) =>
  !!item.link && !isAnchorLink(item.link) && item.link.href === href;

const isFolder = (link: LinkProps | undefined) => isEmptyObject(link);

const getItemsWithIsSelected = (
  items: Array<ExpandableMenuItemProps>,
  href = '',
): Array<ExpandableMenuItemWithIsSelected> => {
  return items.map(item => {
    const isActiveItem = getIsActiveItem(item, href);
    const subItems = item.items || [];
    const subItemsWithIsSelected = getItemsWithIsSelected(subItems, href);
    const isActiveSubMenu = subItemsWithIsSelected.some(
      child => child.isSelected,
    );
    const isSelected =
      isActiveItem || (isActiveSubMenu && !isFolder(item.link));

    return {
      ...item,
      items: subItemsWithIsSelected,
      isSelected,
      isCurrent: isActiveItem,
    };
  });
};

const ExpandableMenu: React.FC<ExpandableMenuProps> = ({
  id,
  items,
  currentPrimaryPageHref,
  translate,
  skin = 'ExpandableMenuSkin',
}) => {
  const itemsWithIsSelected = React.useMemo(
    () => getItemsWithIsSelected(items, currentPrimaryPageHref),
    [items, currentPrimaryPageHref],
  );

  const translatedAriaLabel = translate!(
    translations.ARIA_LABEL_NAMESPACE,
    translations.ARIA_LABEL_KEY,
    translations.ARIA_LABEL_DEFAULT,
  );

  return (
    <nav id={id} aria-label={translatedAriaLabel} className={style[skin]}>
      <ul className={style.menuContainer}>
        {itemsWithIsSelected.map((item, index) => (
          <ExpandableMenuItem
            key={index}
            {...item}
            idPrefix={`${id}-${index}`}
            skin={skin}
          />
        ))}
      </ul>
    </nav>
  );
};

export default ExpandableMenu;

import * as React from 'react';
import classNamesFn from 'classnames';
import { MenuContainerProps } from '../MenuContainer.types';
import styles from './styles/ClassicInlinePopup.scss';
import DefaultInlinePopup from './skinComps/DefaultInlinePopup';

const MenuContainer: React.FC<MenuContainerProps> = ({
  id,
  isOpen,
  isVisible,
  fillLayers,
  children,
  classNames,
  meshProps,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (isOpen && !isVisible) {
    isVisible = true;
  }

  const layerIds = {
    overlay: `overlay-${id}`,
    container: `container-${id}`,
    inlineContentParent: `inlineContentParent-${id}`,
  };

  return (
    <div
      id={id}
      tabIndex={0}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-undisplayed={!isVisible}
      className={classNamesFn(
        styles.menuContainer,
        classNames.map(name => styles[name]),
        { [styles.visible]: isVisible, [styles.open]: isOpen },
      )}
    >
      <DefaultInlinePopup
        id={id}
        layerIds={layerIds}
        fillLayers={fillLayers}
        classNames={classNames}
        meshProps={meshProps}
      >
        {children}
      </DefaultInlinePopup>
    </div>
  );
};

export default MenuContainer;
